import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FamiliaApiService } from 'src/app/services/endpoints/familia.service';
import { FamiliaModel } from 'src/app/services/models/familia.model';

@Component({
  selector: 'app-editar-familia',
  templateUrl: './editar-familia.component.html',
  styleUrls: ['./editar-familia.component.scss'],
})
export class EditarFamiliaComponent implements OnInit {
  @Input() familia: any;
  @Input() bonoEco: any;
  editarFamilia;
  constructor(
    public modalController: ModalController,
    private familiaApiService:FamiliaApiService,
  ) { }



  ngOnInit() {

    console.table("Familia",this.familia)
    this.editarFamilia= new FormBuilder().group({
    apellidos:[this.familia.nombre,Validators.required],   
    movil:[this.familia.movil,[Validators.required, Validators.pattern("[0-9]{9}")]],
    nif:[this.familia.nif,[Validators.required, Validators.pattern("[0-9]{8}[A-Za-z]{1}")]],
    miembros:['',Validators.required],
    bono:[this.bonoEco.bonoEcoId,Validators.required,],   
  });

  }
  onSubmit(){
    const familiaId=this.familia._id;
    const newFamilia = new FamiliaModel();
    newFamilia.nombre = this.editarFamilia.value.apellidos;
    newFamilia.movil = this.editarFamilia.value.movil;
    this.familiaApiService.modifyFamilia(familiaId,newFamilia);
    this.buttonBack(true);
  }

  buttonBack(reload = false){
    this.modalController.dismiss({
      'dismissed': true,
      'reload':reload
    });
  }
}
