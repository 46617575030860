import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BonoecoApiService } from 'src/app/services/endpoints/bonoeco.service';
import { FamiliaApiService } from 'src/app/services/endpoints/familia.service';

@Component({
  selector: 'app-informacion-bono',
  templateUrl: './informacion-bono.component.html',
  styleUrls: ['./informacion-bono.component.scss'],
})
export class InformacionBonoComponent implements OnInit {
  @Input() bonoId: string;

  bono;
  familia;

  constructor(
    private modalController: ModalController,
    private familiaApiService: FamiliaApiService,
    private bonoApiService: BonoecoApiService
  ) { }

  ngOnInit() {
    console.log(this.bonoId);
    this.getBonoById();
  }


  //BONO POR ID
  getBonoById(){
    this.bonoApiService.getBonoecoById(this.bonoId).then((response:any)=>{
      this.bono = response.data;
      this.getFamiliaById(this.bono.familia)
    });
  }
  
  //FAMILIA POR ID
  getFamiliaById(familiaId){
    this.familiaApiService.getFamiliaById(familiaId).then((response:any)=>{
    this.familia = response.data;
  });
 }

  //VOLVER
  buttonBack(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
