import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchParams } from 'src/app/services/base/api.base.service';
import { AdministradorApiService } from 'src/app/services/endpoints/administrador.service';
import { UsuarioApiService } from 'src/app/services/endpoints/usuario.service';

@Component({
  selector: 'app-detalles-usuario',
  templateUrl: './detalles-usuario.component.html',
  styleUrls: ['./detalles-usuario.component.scss'],
})
export class DetallesUsuarioComponent implements OnInit {

  administrador;
  usuario;
  constructor(
    public modalController: ModalController,
    private usuarioApiService: UsuarioApiService,
    private administradorApiService: AdministradorApiService,
    ) {}

  ngOnInit() {
    this.getMyUser()
  }

  buttonBack(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  //TRAE DATOS USUARIO

  getMyUser(){
  this.usuarioApiService.getSession().then((response:any)=>{
    const usuario = response;
    this.usuario = usuario;
    const params: SearchParams = {
      domain: [
        {
          field: "correo",
          operation: "$eq",
          values: usuario.correo,
        },
      ],
      limit:1,
    };

    this.administradorApiService.getAdministrador(params).then((response:any)=>{
      this.administrador = response.data[0];
    })
    
    
  })

}
}
