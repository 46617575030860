import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, MenuController } from '@ionic/angular';
import { StorageService } from './services/base/storage.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  path;
  tk;
  constructor(
     private storageS: StorageService, 
     private router:Router, 
     private menu: MenuController,
     private alertController: AlertController)
 {
    this.path = window.location.pathname;
    this.tk = this.storageS.getToken();
  }
  
  getToken(){
    return this.storageS.getToken() || null;
  }
  goInicio(){ 
    this.tk = this.storageS.getToken();
    this.router.navigate(['home'])
  }

  goFamilias(){
    this.tk = this.storageS.getToken();
    this.router.navigate(['clientes'])
  }

  goComercios(){
    this.tk = this.storageS.getToken();
    this.router.navigate(['comercios'])
  }

  async logout() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Atención',
      message: '¿Desea abandonar la sesión?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Sí',
          id: 'confirm-button',
          handler: () => {

            this.tk =null;
            this.storageS.deleteToken();
            localStorage.clear();
            this.router.navigate(["/login"]);

            console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();  
  }


  goBonos(){
    this.router.navigate(['bonos'])
  }

  
}
