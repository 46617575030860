import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Orders, SearchParams } from 'src/app/services/base/api.base.service';
import { ComercioApiService } from 'src/app/services/endpoints/comercio.service';
import { ServicioApiService } from 'src/app/services/endpoints/servicio.service';
import { ComercioModel } from 'src/app/services/models/comercio.modal';

@Component({
  selector: 'app-editar-comercio',
  templateUrl: './editar-comercio.component.html',
  styleUrls: ['./editar-comercio.component.scss'],
})
export class EditarComercioComponent implements OnInit {
  @Input() comercio: any;

  editarComercio;
  categorias;

  constructor(
    public modalController:ModalController,
    private comercioApiService: ComercioApiService,
    private servicioApiService: ServicioApiService
  ) { }

  
  ngOnInit() {
      let order: Array<Orders> = [ {
            clave: "nombre",
            valor: 1 //desc
        },
        ];
      const params: SearchParams = {
        order
      };
      // * Buscamos los servicios por orden alfabético
      this.servicioApiService.getServicio(params).then((response:any)=>{
        console.log("categorias", response.data)
        this.categorias = response.data;
      })
  
   this.editarComercio= new FormBuilder().group({
      nombre:[this.comercio.nombre,Validators.required],   
      movil:[this.comercio.movil,[Validators.required, Validators.pattern("[0-9]{9}")]],
      cif:[this.comercio.cif, [Validators.required, Validators.pattern('([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])')]],
      categoria:[this.comercio.servicio,Validators.required],
      direccion:[this.comercio.direccion,Validators.required],
      codigoPostal:[this.comercio.codigoPostal, [Validators.required, Validators.pattern("(0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3})")]],
      localidad:[this.comercio.localidad,Validators.required],
     });
  
  }

  onSubmit(){
    const comercioId =this.comercio._id;
    const newComercio = new ComercioModel();
    newComercio.nombre = this.editarComercio.value.nombre;
    newComercio.movil = this.editarComercio.value.movil;
    newComercio.cif = this.editarComercio.value.cif;
    newComercio.servicio = this.editarComercio.value.servicio;
    newComercio.direccion =this.editarComercio.value.direccion;
    newComercio.codigoPostal = this.editarComercio.value.codigoPostal;
    newComercio.localidad = this.editarComercio.value.localidad;

    this.comercioApiService.modifyComercio(comercioId,newComercio);
    this.buttonBack(true);
  }


  buttonBack(reload = false){
    this.modalController.dismiss({
      'dismissed': true,
      'reload':reload
    });
  }

}
